<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        v-if="visibleFields.length !== 0"
        class="position-relative commitmentTableView"
        primary-key="number"
        show-empty
        responsive
        striped
        hover
        empty-text="Não há empenhos cadastrados."
        :items="commitments"
        :fields="visibleFields"
        :busy="isLoading"
        :sort-by.sync="sortOptions.sortBy"
        :sort-desc.sync="sortOptions.isSortDirDesc"
        tbody-tr-class="commitmentTableCustomHeight"
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner
              variant="info"
              class="align-middle mb-1"
            />
            <br>
            <strong class="pt-2">Carregando...</strong>
          </div>

        </template>

        <template #cell(id)="field">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'order-commitment-detail', params: { id: field.item.order, idcommitment: field.item.id } } "
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ field.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(situation)="field">
          <b-media vertical-align="center">
            <span
              v-b-tooltip.hover
              :title="setTooltipTitleAudit(field.item.situation)"
              :class="getAuditSvgClass(field.item.situation)"
            >
              <svg-icon
                type="mdi"
                size="22"
                :path="mdiFileSearch"
              />
            </span>
            <span
              v-b-tooltip.hover
              :title="setTooltipTitleDone(field.item.situation)"
              class="ml-50"
              :class="field.item.situation === 'done' ? 'commitmentIsDone' : ''"
            >
              <svg-icon
                type="mdi"
                size="22"
                :path="mdiCheckDecagram"
              />
            </span>
          </b-media>
        </template>

        <template #cell(order__contract__bidding__client__name)="field">
          <span class="font-weight-bold text-nowrap">
            {{ field.item.client_set.name }}
          </span>
        </template>

        <template #cell(order__contract__bidding__trade_number)="field">
          <span class="font-weight-bold text-nowrap">
            {{ field.item.trade_number }}
          </span>
        </template>

        <template #cell(company__name_fantasy)="field">
          <span class="font-weight-bold text-nowrap">
            {{ field.item.company_set.name_fantasy }}
          </span>
        </template>

        <template #cell(number)="field">
          <span class="text-nowrap">
            {{ field.item.number }}
          </span>
        </template>

        <template #cell(date_delivery)="field">
          <span class="text-nowrap">
            {{ moment(field.item.date_delivery).format('DD/MM/YYYY') }}
          </span>
        </template>

        <template #cell(date_expiration)="field">
          <span class="text-nowrap">
            {{ moment(field.item.date_expiration).format('DD/MM/YYYY') }}
          </span>
        </template>

        <template #cell(price)="field">
          <span class="text-nowrap">
            R$ {{ field.item.price | formatCurrency() }}
          </span>
        </template>

        <template #cell(status__name)="field">
          <div
            class="d-block text-nowrap"
          >
            <b-badge
              pill
              class="text-capitalize sizeMin px-1 d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': field.item.status_set.color}"
            >
              {{ field.item.status_set.name }}
            </b-badge>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-if="visibleFields.length !== 0"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="filter.page_size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  VBTooltip,
  BBadge,
  BSpinner,
  BMedia,
  BLink,
} from 'bootstrap-vue'

import {
  mdiCheckDecagram,
  mdiFileSearch,
} from '@mdi/js'

import { useToast } from 'vue-toastification/composition'

import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import router from '@/router'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import MetaData from '@/components/utils/metaData'
import debounce from '@/components/utils/debounce'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BBadge,
    BSpinner,
    BMedia,
    BLink,
    SvgIcon,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['ready'],

  data() {
    const totalItems = 0
    const currentPage = 1

    const sortOptions = {
      sortBy: 'date_expiration',
      isSortDirDesc: false,
    }

    const commitments = []

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'situation',
        label: 'Situação',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap',
      },
      {
        key: 'order__contract__bidding__client__name',
        label: 'Órgão',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap',
      },
      {
        key: 'order__contract__bidding__trade_number',
        label: 'Nº Pregão',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap',
      },
      {
        key: 'company__name_fantasy',
        label: 'Empresa',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap commitmentTableColumn',
      },
      {
        key: 'number',
        label: 'N Empenho',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap commitmentTableColumn',
      },
      {
        key: 'date_delivery',
        label: 'Prazo de Entrega',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap commitmentTableColumn',
      },
      {
        key: 'date_expiration',
        label: 'Recebimento do empenho',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap commitmentTableColumn',
      },
      {
        key: 'price',
        label: 'Valor do empenho',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap commitmentTableColumn',
      },
      {
        key: 'status__name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap commitmentTableColumn',
      },
    ]

    const lastSearch = ''

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('commitment')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const fetchId = ''

    const meta = new MetaData('CommitmentTable')

    return {
      totalItems,
      currentPage,
      tableColumns,
      lastSearch,
      syncLoad,
      isLoading,
      fetchId,
      commitments,
      sortOptions,
      meta,
    }
  },
  computed: {
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
  },
  watch: {
    filter: {
      handler: function updateFilter(val) {
        this.currentPage = 1
        if (this.lastSearch !== val.search) {
          this.lastSearch = val.search
        }

        this.fetchData()
      },
      deep: true,
    },
    'sortOptions.sortBy': function updateSortBy() {
      this.currentPage = 1
      this.fetchData()
    },
    'sortOptions.isSortDirDesc': function updateIsSortDirDesc() {
      this.currentPage = 1
      this.fetchData()
    },
    currentPage: function updateCurrentPage() {
      this.fetchData()
    },
  },
  created() {
    this.sortOptions = this.meta.bind(this.sortOptions, 'sortOptions')
    this.fetchData()
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiCheckDecagram,
      mdiFileSearch,
      moment,
    }
  },
  methods: {
    refreshColumns(columns) {
      columns.forEach(element => {
        this.tableColumns.forEach((obj, i) => {
          if (obj.key === element.key) {
            this.tableColumns[i].visible = element.visible
          }
        })
      })
    },
    fetchData() {
      this.isLoading = true
      debounce.call(this.fetch)
    },
    fetch() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      const filterOrder = this.filter

      filterOrder.p = this.currentPage

      if (this.sortOptions.isSortDirDesc) {
        filterOrder.ordering = `-${this.sortOptions.sortBy}`
      } else {
        filterOrder.ordering = this.sortOptions.sortBy
      }
      syslic
        .order
        .commitment
        .fetchCommitmentList(filterOrder)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.totalItems = response.data.count
            this.commitments = response.data.results
            promise = new Promise(resolve => {
              resolve()
            })
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }

          return promise
        })
        .then(() => {
          this.syncLoad.ready('commitment')
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados dos empenhos.',
                text: 'Não foi possível ler os dados dos empenhos do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.syncLoad.ready('commitment')
          }
        })
    },
    onRowSelected(item) {
      router.push({
        name: 'order-commitment-detail',
        params: {
          id: item.order,
          idcommitment: item.id,
          goBackTo: 'commitment',
        },
      })
        .catch(() => {})
    },
    getAuditSvgClass(situation) {
      const svgClassMap = {
        audit: 'commitmentInAudit',
        declined: 'commitmentDeclined',
      }

      return svgClassMap[situation] || ''
    },
    setTooltipTitleAudit(situation) {
      const tooltipTitle = {
        audit: 'Entrega em Auditoria.',
        declined: 'Entrega Declinada.',
      }

      return tooltipTitle[situation] || ''
    },
    setTooltipTitleDone(situation) {
      const tooltipTitle = {
        done: 'Entrega Concluída.',
        idle: 'Entrega Aberta.',
      }

      return tooltipTitle[situation] || ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.commitmentTableColumn {
  min-width: 100px;
}

.commitmentTableView {
  border-radius: 5px;
}

.commitmentTableCustomHeight {
  height: 4.5rem;
}

.sizeMin{
  min-width: 110px
}

.commitmentIsDone {
  color: $success_2;
}

.commitmentInAudit {
  color: #FF730E;
}

.commitmentDeclined {
  color: #F50F00;
}
</style>
