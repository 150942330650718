<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <commitment-filter
            :visible-columns="tableColumns"
            @filter-change="updateFilter"
            @columns-change="updateColumns"
            @ready="syncLoad.ready('filter')"
          />
        </b-col>
      </b-row>
      <b-row
        class="justify-content-center mt-1"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <commitment-table
            ref="refCommitmentTable"
            :filter="filter"
            active
            @ready="syncLoad.ready('table')"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'

import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import CommitmentTable from '@/views/order/commitment/CommitmentTable.vue'
import CommitmentFilter from '@/views/order/commitment/CommitmentFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    SpinLoader,
    CommitmentTable,
    CommitmentFilter,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const filter = {
      page_size: 10,
      search: '',
    }

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('table')
    syncLoad.addTrigger('filter')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'situation',
        label: 'Situação',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'order__contract__bidding__client__name',
        label: 'Órgão',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'order__contract__bidding__trade_number',
        label: 'Nº Pregão',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'company__name_fantasy',
        label: 'Empresa',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'number',
        label: 'N Empenho',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'date_delivery',
        label: 'Prazo de Entrega',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'date_expiration',
        label: 'Recebimento do empenho',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'price',
        label: 'Valor do empenho',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'status__name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'note_number',
        label: 'Nº da Nota',
        visible: false,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap deliveryTableColumn',
      },
      {
        key: 'billing_date',
        label: 'Data de Faturamento',
        visible: false,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap deliveryTableColumn',
      },
      {
        key: 'pay_date',
        label: 'Previsão de Pagamento',
        visible: false,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap deliveryTableColumn',
      },
    ]

    return {
      isLoading,
      filter,
      tableColumns,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
  },
  methods: {
    updateColumns(val) {
      this.$refs.refCommitmentTable.refreshColumns(val)
    },
    updateFilter(newFilter) {
      this.filter = { ...this.filter, ...newFilter }
    },
  },
  setup() {
    return {
    }
  },
}
</script>
